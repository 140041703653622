import KdTree from 'static-kdtree'; // Import the KD-tree library
import * as THREE from 'three'; // Import the THREE.js library


function arrayNeedsUint32(array) {

    // assumes larger values usually on last

    for (let i = array.length - 1; i >= 0; --i) {

        if (array[i] >= 65535) return true; // account for PRIMITIVE_RESTART_FIXED_INDEX, #24565

    }

    return false;

}

const getVertices = (geometry) => {
    const position = geometry.attributes.position;
    const vertices = [];
    let seenVertex = {}
    let current = 0;
    let uniqueVertices = []
    for (let i = 0; i < position.count; i++) {
        vertices.push([position.getX(i), position.getY(i), position.getZ(i)]);

        let vertex = [position.getX(i), position.getY(i), position.getZ(i)]

        if (seenVertex[vertex] == undefined) {
            seenVertex[vertex] = current;
            uniqueVertices.push(vertex)
            current++;
        }
    }
    return uniqueVertices;
}

const parseGeometry = (geometry, computeNormals) => {
    const position = geometry.attributes.position;
    const vertices = [];
    const faces = [];
    const indexFaces = [];
    let seenVertex = {}

    let current = 0;
    let uniqueVertices = []

    if (geometry.index) {
        let normals_1 = []
        let uniqueVertices_1 = []
        let faces_1 = []

      
        const triangles = geometry.index.array
        const verts = geometry.attributes.position.array


        for (let i = 0; i < geometry.index.count; i += 3) {
            faces_1.push([triangles[i], triangles[i + 1], triangles[i + 2]])
        }
        for (let i = 0; i < verts.length; i += 3) {
            uniqueVertices_1.push([verts[i], verts[i + 1], verts[i + 2]])
        }
        // for (let i = 0; i < geometry.attributes.normal.count; i += 3) {
        //     normals_1.push([normals[i], normals[i + 1], normals[i + 2]])
        // }
        return [uniqueVertices_1, faces_1, geometry.attributes.normal]
    }
    for (let i = 0; i < position.count; i += 3) {

        let vertex0 = [position.getX(i), position.getY(i), position.getZ(i)]
        let vertex1 = [position.getX(i + 1), position.getY(i + 1), position.getZ(i + 1)]
        let vertex2 = [position.getX(i + 2), position.getY(i + 2), position.getZ(i + 2)]


        if (seenVertex[vertex0] == undefined) {
            seenVertex[vertex0] = current;
            uniqueVertices.push(vertex0)
            vertices.push(vertex0[0])
            vertices.push(vertex0[1])
            vertices.push(vertex0[2])
            current++;
        }
        if (seenVertex[vertex1] == undefined) {
            seenVertex[vertex1] = current;
            uniqueVertices.push(vertex1)
            vertices.push(vertex1[0])
            vertices.push(vertex1[1])
            vertices.push(vertex1[2])
            current++;
        }
        if (seenVertex[vertex2] == undefined) {
            seenVertex[vertex2] = current;
            uniqueVertices.push(vertex2)
            vertices.push(vertex2[0])
            vertices.push(vertex2[1])
            vertices.push(vertex2[2])
            current++;
        }
        faces.push([seenVertex[vertex0], seenVertex[vertex1], seenVertex[vertex2]]);
        indexFaces.push([seenVertex[vertex0]])
        indexFaces.push([seenVertex[vertex1]]);
        indexFaces.push([seenVertex[vertex2]]);
    }
    // geometry.setIndex(faces)
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));
    geometry.setAttribute('faces', new THREE.BufferAttribute(new Int32Array(faces), 1));
    let index = new (arrayNeedsUint32(faces) ? THREE.Uint32BufferAttribute : THREE.Uint16BufferAttribute)(indexFaces, 1);
    geometry.setIndex(index)
    let normals = []
    // geometry.computeVertexNormals()
    if (computeNormals){
        normals = vertexNormals(index, geometry)
    } else {
        normals = geometry.attributes.normal
    }
    return [uniqueVertices, faces, normals]
}

const getNormals = (geometry) => {
    geometry.computeVertexNormals();
    const normals = geometry.attributes.normal;
    const normals_check = [];
    let seenNormal = {}
    let current = 0;
    let uniqueNormals = []
    for (let i = 0; i < normals.count; i++) {
        normals_check.push([normals.getX(i), normals.getY(i), normals.getZ(i)]);

        let normal = [normals.getX(i), normals.getY(i), normals.getZ(i)]

        if (seenNormal[normal] == undefined) {
            seenNormal[normal] = current;
            uniqueNormals.push(normal)
            current++;
        }
    }
    return uniqueNormals;

}

const build_adjacency = (geometry, faces) => {
    const position = geometry.attributes.position;

    //build vertices array and indices for vertices
    // const vertices = [];
    // let seenVertex = {}
    // let current = 0;

    // for (let i = 0; i < position.count; i++) {
    //     vertices.push([position.getX(i), position.getY(i), position.getZ(i)]);

    //     let vertex = [position.getX(i), position.getY(i), position.getZ(i)]

    //     if (seenVertex[vertex] == undefined) {
    //         seenVertex[vertex] = current;
    //         current++;
    //     }
    // }

    // // build faces array

    // let faces = []
    // vertices.map((v, i) => {
    //     if (i != 0 && i % 3 == 0) {
    //         faces.push([seenVertex[vertices[i - 3]], seenVertex[vertices[i - 2]], seenVertex[vertices[i - 1]]])
    //     }
    // }
    // )
    // build local adjacency for each vertex

    let adjacency = {}
    faces.map((f, i) => {
        for (let x = 0; x < 3; x++) {
            let v = f[x];

            if (!adjacency[v]) {
                adjacency[v] = new Set()
            }
            adjacency[v].add(f[(x + 1) % 3])
            adjacency[v].add(f[(x + 2) % 3])
        }
    })
    return adjacency
}



function vertexNormals(index, geometry) {

    let positionAttribute = geometry.attributes.position
    let normalAttribute = new THREE.BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);
    geometry.setAttribute('normal', normalAttribute);



    const pA = new THREE.Vector3(), pB = new THREE.Vector3(), pC = new THREE.Vector3();
    const nA = new THREE.Vector3(), nB = new THREE.Vector3(), nC = new THREE.Vector3();
    const cb = new THREE.Vector3(), ab = new THREE.Vector3();

    // indexed elements

    if (index) {

        for (let i = 0, il = index.count; i < il; i += 3) {

            const vA = index.getX(i + 0);
            const vB = index.getX(i + 1);
            const vC = index.getX(i + 2);

            pA.fromBufferAttribute(positionAttribute, vA);
            pB.fromBufferAttribute(positionAttribute, vB);
            pC.fromBufferAttribute(positionAttribute, vC);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            cb.cross(ab);

            nA.fromBufferAttribute(normalAttribute, vA);
            nB.fromBufferAttribute(normalAttribute, vB);
            nC.fromBufferAttribute(normalAttribute, vC);

            nA.add(cb);
            nB.add(cb);
            nC.add(cb);

            normalAttribute.setXYZ(vA, nA.x, nA.y, nA.z);
            normalAttribute.setXYZ(vB, nB.x, nB.y, nB.z);
            normalAttribute.setXYZ(vC, nC.x, nC.y, nC.z);

        }
    }

    const normals = geometry.attributes.normal;

    for (let i = 0, il = normals.count; i < il; i++) {
        let _vector = new THREE.Vector3()
        _vector.fromBufferAttribute(normals, i);

        _vector.normalize();

        normals.setXYZ(i, _vector.x, _vector.y, _vector.z);

    }
    return normals

}

function knn_bfs(geometry, radius, start_index, faces, distances =false, adj=false) {
    const adjacency = build_adjacency(geometry, faces);

    const visited = new Set();
    const queue = [[start_index, 0]];
    const nearest_vertices = [];

    while (queue.length > 0) {
        const [vertex, distance] = queue.shift();
        if(distance > radius){
            break;
        }
        if (!visited.has(vertex)) {
            visited.add(vertex);
            nearest_vertices.push([vertex, distance]);

            // Check if adjacency[vertex] exists and is a Set
            if (adjacency[vertex] && adjacency[vertex] instanceof Set) {
                adjacency[vertex].forEach(neighbor => {
                    queue.push([neighbor, distance + 1]);
                });
            }
        }
    }
    let nearest = []
    if (!distances){
        nearest = nearest_vertices.map(([vertex, _]) => (vertex));
    }
    else{
        nearest = nearest_vertices
    }
    if (!adj){
        return nearest;
    } else {
        return [nearest, adjacency]
    }
}

function knn_bfsbrushing(geometry, radius, start_index, faces, vertices, distances = false, adj = false) {
    const adjacency = build_adjacency(geometry, faces);

    const visited = new Set();
    const queue = [[start_index, 0]];
    const startelem = new THREE.Vector3(vertices[start_index][0], vertices[start_index][1], vertices[start_index][2]);
    const nearest_vertices = [];

    while (queue.length > 0) {
        const [vertex, distance] = queue.shift();
        const currDist = startelem.distanceTo(new THREE.Vector3(vertices[vertex][0], vertices[vertex][1], vertices[vertex][2]));
        if (currDist > radius) {
            break;
        }
        if (!visited.has(vertex)) {
            visited.add(vertex);
            nearest_vertices.push([vertex, distance]);

            // Check if adjacency[vertex] exists and is a Set
            if (adjacency[vertex] && adjacency[vertex] instanceof Set) {
                adjacency[vertex].forEach(neighbor => {
                    queue.push([neighbor, distance + 1]);
                });
            }
        }
    }

    // If nearest_vertices has 2 or fewer elements, add the next 3 nearest vertices without distance check
    if (nearest_vertices.length <= 2) {
        let added = 0;
        while (added < 1 && queue.length > 0) {
            const [vertex, distance] = queue.shift();
            if (!visited.has(vertex)) {
                visited.add(vertex);
                nearest_vertices.push([vertex, distance]);
                added++;

                // Check if adjacency[vertex] exists and is a Set
                if (adjacency[vertex] && adjacency[vertex] instanceof Set) {
                    adjacency[vertex].forEach(neighbor => {
                        if (!visited.has(neighbor)) {
                            queue.push([neighbor, distance + 1]);
                        }
                    });
                }
            }
        }
    }

    console.log("nearest vert: ", nearest_vertices);
    let nearest = [];
    if (!distances) {
        nearest = nearest_vertices.map(([vertex, _]) => vertex);
    } else {
        nearest = nearest_vertices;
    }
    if (!adj) {
        return nearest;
    } else {
        return [nearest, adjacency];
    }
}


function distance(point, inner_surface) {
    try {
        const kdtree = new KdTree(inner_surface);
        const nearest = kdtree.knn(point, 1)[0];
        const nearest_vertex = inner_surface[nearest];
        const nearest_vertex_vector = new THREE.Vector3(nearest_vertex[0], nearest_vertex[1], nearest_vertex[2]);
        const point_vector = new THREE.Vector3(point[0], point[1], point[2]);
        const dist = nearest_vertex_vector.distanceTo(point_vector);
        const mindistance = Math.sqrt(
            Math.pow(inner_surface[nearest][0] - point[0], 2) +
            Math.pow(inner_surface[nearest][1] - point[1], 2) +
            Math.pow(inner_surface[nearest][2] - point[2], 2)
        );
        return mindistance;
    }
    catch (e) {
        console.log(e)
    }
}

export function computeCotangents(start_ind, neighbors, vertices){
    const startVertex = new THREE.Vector3(...vertices[start_ind]);
    const cotangents = [];

    for (let i = 0; i < neighbors.length; i++){
        // const neighbor1 = 
    }

}

function nRingNeighbors(adjacencyMap, startVertex, n) {
    if (n < 0) return []; // No negative ring values

    let visited = new Set();
    let queue = [{ vertex: startVertex, depth: 0 }];
    let nRingNeighbors = new Set();

    while (queue.length > 0) {
        let { vertex, depth } = queue.shift();

        if (depth > n) continue; // Stop if we exceed the depth

        // If we are exactly at depth n, add to result
        if (depth === n) {
            nRingNeighbors.add(vertex);
        }

        // Explore neighbors
        for (let neighbor of adjacencyMap[vertex] || []) {
            if (!visited.has(neighbor)) {
                visited.add(neighbor);
                queue.push({ vertex: neighbor, depth: depth + 1 });
            }
        }
    }

    // Convert Set to Array if needed
    return nRingNeighbors;
}

// Export the function
export { build_adjacency, knn_bfs, distance, getVertices, getNormals, parseGeometry, nRingNeighbors,knn_bfsbrushing };




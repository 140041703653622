import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const MeshDistortion = () => {
  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
  const rendererRef = useRef(new THREE.WebGLRenderer({ antialias: true }));
  const meshRef = useRef(null);
  const raycasterRef = useRef(new THREE.Raycaster());
  const mouseRef = useRef(new THREE.Vector2());
  const uniformsRef = useRef({
    time: { value: 1.5 },
    point: { value: new THREE.Vector3() },
    radius: { value: 0.5 },
    strength: { value: 0.2 },
  });

  useEffect(() => {
    const scene = sceneRef.current;
    const camera = cameraRef.current;
    const renderer = rendererRef.current;
    const mount = mountRef.current;
    const uniforms = uniformsRef.current;

    renderer.setSize(window.innerWidth, window.innerHeight);
    mount.appendChild(renderer.domElement);

    camera.position.z = 5;

    const geometry = new THREE.SphereGeometry(1, 64, 64);
    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader: `
        uniform float time;
uniform vec3 point;
uniform float radius;
uniform float strength;
varying vec3 vNormal;

float smoothFalloff(float distance, float maxDistance) {
  float x = distance / abs(maxDistance);
  return 1.0 - (3.0 * x * x - 2.0 * x * x * x);
}

void main() {
  vNormal = normal;
  vec3 newPosition = position;
  float dist = distance(position, point);
  if (dist < radius) {
    float falloff = smoothFalloff(dist, radius);
    newPosition += normal * strength * falloff;
  }
  gl_Position = projectionMatrix * modelViewMatrix * vec4(newPosition, 1.0);
}

      `,
      fragmentShader: `
        varying vec3 vNormal;
        void main() {
          vec3 light = vec3(0.5, 0.2, 1.0);
          light = normalize(light);
          float dProd = max(0.0, dot(vNormal, light));
          gl_FragColor = vec4(dProd, dProd, dProd, 1.0);
        }
      `,
    });

    const mesh = new THREE.Mesh(geometry, material);

    const light = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(light);
    scene.add(mesh);
    meshRef.current = mesh;

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableRotate=  false;
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;

    let isDeforming = false;

    const animate = () => {
      requestAnimationFrame(animate);
      uniforms.time.value += 0.01;
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    const startDeforming = (event) => {
      isDeforming = true;
      updateDeformation(event);
    };

    const stopDeforming = () => {
      isDeforming = false;
    };

    const updateDeformation = (event) => {
      if (!isDeforming) return;
    
      updateMousePosition(event);
      raycasterRef.current.setFromCamera(mouseRef.current, camera);
      const intersects = raycasterRef.current.intersectObject(meshRef.current);
    
      if (intersects.length > 0) {
        // Accumulate deformation
        const newPoint = intersects[0].point;
        const currentPoint = uniforms.point.value;
    
        // Interpolate between current and new points
        uniforms.point.value.lerp(newPoint, 0.1);
      }
    };
    

    const updateMousePosition = (event) => {
      mouseRef.current.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouseRef.current.y = -(event.clientY / window.innerHeight) * 2 + 1;
    };

    window.addEventListener('mousedown', startDeforming);
    window.addEventListener('mousemove', updateDeformation);
    window.addEventListener('mouseup', stopDeforming);

    return () => {
      controls.dispose();
      window.removeEventListener('mousedown', startDeforming);
      window.removeEventListener('mousemove', updateDeformation);
      window.removeEventListener('mouseup', stopDeforming);
      mount.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef} />;
};

export default MeshDistortion;